import React, { Component } from 'react'; 
import { Redirect } from 'react-router-dom';
import { PostData } from './PostData.js';
import { api_url } from '../Config';
import logo from '../assets/images/demo-logo.png';
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      redirectToReferrer: false,
      emailError: '',
      passError: '',
      validError: '',
      keeplogin: true,
      base_url: api_url.base_url,
      img_url: api_url.img_url,
      customers: '',
      order_type: 8,
      totalorder: 1,
      paty_ledger_id: null,
      warehouse_id: 1,
      editData: [],
      empolyee_id: 0,
      orderId: (this.props.match.params.orderId !== 'undefined') ? this.props.match.params.orderId : '',
      loading: false,
    };
    this.login = this.login.bind(this);
    this.valid = this.valid.bind(this);
    this.keepmelogin = this.keepmelogin.bind(this)
  }

  componentDidMount() {


  }

  componentWillMount() {
    if (localStorage.getItem('userData')) {
      this.getUserData();
      this.setState({ redirectToReferrer: true });
    }
    else {
      this.setState({ redirectToReferrer: false });
    }
  }
  getUserData() {
    localStorage.getItem("userData");

  }

  valid() {
    if (this.state.email === '' && this.state.password === "") {
      this.setState(
        {
          emailError: "Enter Email or Mobile to Continue...",
          passError: "Enter Your Password to Continue..."
        })
    } else if (this.state.password === "") {
      this.setState(
        {
          emailError: "",
          passError: "Enter Your Password to Continue..."
        })
    } else if (this.state.email === "") {
      this.setState({ emailError: "Enter Email or Mobile to Continue...", passError: "" })
    }
    else {
      return true
    }
  }


  login(e) {
    e.preventDefault();
    if (this.valid()) {
      this.setState({ emailError: "", passError: "", validError: "", loading: true })

      PostData('login', this.state).then((result) => {
        if (result.warehouse_id != '') {
          this.setState({ warehouse_id: result.warehouse_id })
        }
        this.setState({ paty_ledger_id: result.paty_ledger_id, empolyee_id: result.empolyee_id })
        let responseJson = result;
        if (responseJson.userData) {
          const order_not = this.state.totalorder

          localStorage.setItem("customer_id", 1);
          localStorage.setItem("order_type", 'Cart');
          localStorage.setItem("empolyee_id", this.state.empolyee_id);
          localStorage.setItem("user_id", responseJson.userData);
          localStorage.setItem("order_no", order_not);
          localStorage.setItem("paty_ledger_id", this.state.paty_ledger_id);
          localStorage.setItem("warehouse_id", this.state.warehouse_id);
          localStorage.setItem('userData', responseJson.userData);
          sessionStorage.setItem('userData', responseJson.userData);
          const getUrl = this.state.base_url + "sale_api/getNextPosNo/" + responseJson.userData;
          fetch(getUrl)
            .then((res) => res.json())
            .then(
              (result) => {
                this.setState(
                  {
                    totalorder: result,
                    loading: false,
                    redirectToReferrer: true
                  });
                localStorage.setItem("next_pos_no", result);
              },
              (error) => {
                this.setState({ loading: false });
                this.setState({ redirectToReferrer: true });
              }
            );


        }
        else
          this.setState({ validError: "worng Username or Password", loading: false });
      });
    }
  }

  keepmelogin(e) {
    let isChecked = e.target.checked;
    if (isChecked === true) {
      this.setState({ keeplogin: true }, function () {

      })
    } else {
      this.setState({ keeplogin: false })
    }
  }
  render() {
    const { loading } = this.state;
    if (this.state.redirectToReferrer) {

      // return (<Redirect to="/dashboard" />)
      return (<Redirect to={`/dashboard/${localStorage.getItem('userData')}`} />)
      // return (<Redirect to={`${localStorage.getItem('orderId')? `/item-selection/${localStorage.getItem('orderId')}` : /item-selection/}`} />)

    }
    return (
      <div>

        <div className="main_body" id="login_section">
          <div className="login_screen" id="uder_eng_language">
            <div className="logo login_page">
              <img src={logo} alt="logo" />
            </div>

            <div className="user_login">
              <form onSubmit={this.login}>
                <div className="form_box">
                  <input type="text" placeholder="Username" name="email" onChange={(event) => this.setState({ email: event.target.value })} />
                  <svg width="20px" height="20px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path d="M512 64q39 0 80.5 15.5t72.5 39 51 52 20 53.5v160q0 57-24 127t-71 106q-8 7-14 16.5t-8.5 20T617 675q5 36 37 51l279 132 2 .5 4 1.5 5 3 5.5 4.5 5 6.5 3.5 10 1 13v62l-895 1v-65q0-25 27-37l281-133q32-15 36.5-50.5T385 617q-46-37-71.5-107.5T288 384V224q0-33 33-70.5T407 90t105-26zm0-64q-69 0-136.5 33.5t-109.5 86T224 224v160q0 65 30.5 149.5T345 667L64 800q-3 1-7 3t-15 9-19.5 14T7 843.5 0 863v97q0 26 18.5 44.5T64 1023h896q26 0 44.5-18.5T1023 960v-97q0-10-6.5-20.5t-16-18T982 811t-16-9l-6-2-279-133q60-47 89.5-130T800 384V224q0-39-25.5-79.5T709 72t-93-52T512 0z" fill="#626262" /></svg>
                  <p className="error_box">{this.state.emailError}</p>
                </div>

                <div className="form_box">
                  <input type="password" placeholder="Password" name="password" onChange={(event) => this.setState({ password: event.target.value })} />
                  <svg width="20px" height="20px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 832 1024"><path d="M704 384h-32V262q0-111-72.5-186.5T415 0q-74 0-133 35.5t-90.5 95T160 262v122h-32q-53 0-90.5 37.5T0 512v384q0 53 37.5 90.5T128 1024h576q53 0 90.5-37.5T832 896V512q0-53-37.5-90.5T704 384zM224 262q0-84 53-141t138-57 139 56.5T608 262v122H224V262zm544 634q0 17-8.5 32T736 951.5t-32 8.5H128q-26 0-45-19t-19-45V512q0-26 19-45t45-19h576q26 0 45 19t19 45v384zM416 576q-27 0-45.5 18.5T352 640q0 36 32 55v105q0 13 9.5 22.5T416 832t22.5-9.5T448 800V695q32-19 32-55 0-27-18.5-45.5T416 576z" fill="#626262" /></svg>
                  <p style={{ "textAlign": "left", "color": "red", "fontSize": "12px", "margin": "-9px 0px 38px", "padding": "0 0 0 21px" }} >{this.state.passError}</p>
                </div>
                <p style={{ "color": "red", "fontSize": "12px", "margin": "-24px 0 10px" }}>{this.state.validError}</p>

                <div className="form_box" id="login_items">
                  <button
                    className="button-loadings"
                    style={{ fontSize: "18px" }}
                    type="submit"
                    disabled={loading}
                  >
                    {loading && (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    )}
                    {loading && <span>Loading...</span>}
                    {!loading && <span>Login</span>}
                  </button>

                </div>
              </form>
            </div>

          </div>
        </div>
      </div>
    );
  }
}
export default Login;
