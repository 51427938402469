import React, { Component } from "react";
import $ from "jquery";
import logo from "../assets/images/logo-eloerp.png";
import cart from "../assets/images/cart.svg"; 
import icon8 from "../assets/images/icons8-delete-50.png"; 
import iconBullet from "../assets/images/icons8-bulleted-list-50.png"; 
import { NavLink } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { api_url } from "../Config.js"; 
import loaderImage from "../assets/images/fake-loder.gif";
import homebtn from "../assets/images/homebtn.png"; 
import close_popup from "../assets/images/close_popup.png"; 
import logout from "../assets/images/icons8-logout-rounded-down-96.png"; 
class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      backupProducts: [],
      categories: [],
      warehouse_id: localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : 1,
      redirectToReferrer: false,
      user_id:localStorage.getItem("userData"),
      base_url: api_url.base_url,
      img_url: api_url.img_url,
      loading: false,
      cartProduct:
        JSON.parse(localStorage.getItem("cartProduct")) != null
          ? JSON.parse(localStorage.getItem("cartProduct"))
          : [],
      total_amount: localStorage.getItem("total_amount")
        ? localStorage.getItem("total_amount")
        : 0,

      // cartProduct:[],
      // total_amount:0,
      limit:10,
      start:0,
      dvalue: 0,
      redirect: false,
      prefix: "",
      suffix: "",
      btnLoading: false,
      editData: [],
      decimalValue:null, 
    };
    this.logout = this.logout.bind(this);
    this.selectedCategory= this.selectedCategory.bind(this);
    this.clearSearch= this.clearSearch.bind(this);
    this.loadMore= this.loadMore.bind(this);
  }


componentDidUpdate(){




  localStorage.setItem("user_id", this.state.user_id);

   $(".product_btn img").click(function(){
    $(".sidebar_left").fadeIn();
    $(".overlay_body_box").fadeIn();
});

$(".close_btn_product img,.overlay_body_box").click(function(){
    $(".sidebar_left").fadeOut();
    $(".overlay_body_box").fadeOut();
});




$(".close_popup,.white_overlay,.done_btn").click(function(){
    $(".btm_slide_popup").fadeOut("500");
    $(".white_overlay").fadeOut("500");
});

}

  componentDidMount() {

     const prefixurl = this.state.base_url + "sale_api/GetPrefix/"+this.state.user_id;
    fetch(prefixurl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            prefix: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
      const decimalsUrl = this.state.base_url + "sale_api/getDecimals/"+this.state.user_id;
    fetch(decimalsUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            decimalValue: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
    const sufixurl = this.state.base_url + "sale_api/GetSuffix/"+this.state.user_id;
    fetch(sufixurl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            suffix: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
    this.setState({ btnLoading: true });

    $(".cata-sub-slider").on("scroll", function () {
      var val = $(this).scrollLeft();
      if (
        $(this).scrollLeft() + $(this).innerWidth() >=
        $(this)[0].scrollWidth
      ) {
        $(".cata-sub-slider .nav-next-").hide();
      } else {
        $(".cata-sub-slider .nav-next-").show();
      }
      if (val == 0) {
        $(".cata-sub-slider .nav-prev-").hide();
      } else {
        $(".cata-sub-slider .nav-prev-").show();
      }
    });
    $(".cata-sub-slider .nav-next-").on("click", function () {
      $(".cata-sub-slider").animate({ scrollLeft: "+=460" }, 500);
    });
    $(".cata-sub-slider .nav-prev-").on("click", function () {
      $(".cata-sub-slider").animate({ scrollLeft: "-=460" }, 500);
    });

    $(".cata-sub-nav").on("scroll", function () {
      var val = $(this).scrollLeft();

      if (
        $(this).scrollLeft() + $(this).innerWidth() >=
        $(this)[0].scrollWidth
      ) {
        $(".nav-next").hide();
      } else {
        $(".nav-next").show();
      }

      if (val == 0) {
        $(".nav-prev").hide();
      } else {
        $(".nav-prev").show();
      }
    });
    $(".nav-next").on("click", function () {
      $(".cata-sub-nav").animate({ scrollLeft: "+=43.2" }, 200);
    });
    $(".nav-prev").on("click", function () {
      $(".cata-sub-nav").animate({ scrollLeft: "-=43.2" }, 200);
    });

    this.setState({ loading: true });
    const getUrl = this.state.base_url + "sale_api/getPOSProducts?warehouse_id="+this.state.warehouse_id+"&limit="+this.state.limit+"&start="+this.state.start+"&user_id="+this.state.user_id;
    fetch(getUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            products: result.products,
            backupProducts: result.products,
            loading: false,
            btnLoading: false,
          });
        },
        (error) => {
          this.setState({ loading: false });
        }
      );
      const getCategoriesNames = this.state.base_url+'sale_api/getCategories/'+this.state.user_id;
            fetch(getCategoriesNames,
            {
                method: 'POST',
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify({user_id:this.state.user_id})
            })
            .then((response) => response.json()
                .then((result) => {
                   this.setState({
                     categories:result
                   })
            }))
            .catch((error) => {
                this.setState({loading:false})
            });





    $(".request_door_click").on("click", function () {});
    $(".request_door_click").click(function () {
      $(".process_complete").hide();
    });
    $(".request_door_click").click(function () {
      $(".last_step").show();
    });
  }

  componentWillMount() {
    if (sessionStorage.getItem("userData")) {
      this.getUserData();
    } else if (localStorage.getItem("userData")) {
      this.getUserData();
    } else {
      this.setState({ redirectToReferrer: true });
    }
  }
  getUserData() {
    JSON.parse(sessionStorage.getItem("userData"));
    JSON.parse(localStorage.getItem("userData"));
  }

  searchItem(e) {
    this.setState({ btnLoading: true });
    var search = e.target.value;

    if (search == null) {
      this.setState({
        products: this.state.backupProducts,
      });
    } else {
      this.setState({ loading: true });
      const searchurl =
        this.state.base_url + "sale_api/getSearchResult?search=" + search+"&warehouse_id="+this.state.warehouse_id+"&limit="+this.state.limit+"&start="+this.state.offest+"&user_id="+this.state.user_id;
      fetch(searchurl)
        .then((res) => res.json())
        .then(
          (result) => {
            if (result == null) {
              this.setState({
                products: this.state.backupProducts,
                loading: false,
                btnLoading: false,
              });
            } else {
              this.setState({
                products: result,
                loading: false,
                btnLoading: false,
              });
            }
          },
          (error) => {
            this.setState({ loading: false, btnLoading: false });
          }
        );
    }
  }

  selectedCategory(e) {
     var selector = ".tabs li a";
     $(selector).removeClass("current");
     $('.traget_class_'+e.target.name).addClass("current");
    // $(".sidebar_left").fadeOut();
    // $(".overlay_body_box").fadeOut();
    var select = e.target.name;

    if (select == null || select == 0) {
      this.setState({
        products: this.state.backupProducts,
      });
    } else {
      this.setState({ loading: true, btnLoading: true });
      const selecturl =
        this.state.base_url + "sale_api/getselectCategory?select=" + select+"&warehouse_id="+this.state.warehouse_id+"&limit="+this.state.limit+"&start="+this.state.offest+"&user_id="+this.state.user_id;
      fetch(selecturl)
        .then((res) => res.json())
        .then(
          (result) => {
            if (result == null) {
              this.setState({
                products: this.state.backupProducts,
                loading: false,
                btnLoading: false,
              });
            } else {
              this.setState({
                products: result,
                loading: false,
                btnLoading: false,
              });
            }
          },
          (error) => {
            this.setState({ loading: false, btnLoading: false });
          }
        );
    }
  }


clearSearch(){
  $("#search_here_something").val('');
  this.setState({products:this.state.backupProducts})
}
loadMore(){
  this.setState({btnLoading:true})
  const start = parseFloat(this.state.start) + parseFloat(20);
   const loadUrl = this.state.base_url + "sale_api/getPOSProducts?warehouse_id="+this.state.warehouse_id+"&limit="+this.state.limit+"&start="+start+"&user_id="+this.state.user_id;
    fetch(loadUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.products.length>0) {
              this.setState({
              products: result.products,
              backupProducts: result.products,
              btnLoading: false,
            });
          }
        },
        (error) => {
          this.setState({ btnLoading: false });
        }
      );

}

remove_this_item(id)
{
  const cartProduct = this.state.cartProduct.filter(item => item.name !== id)
  this.setState({cartProduct:cartProduct});
  localStorage.setItem("cartProduct", JSON.stringify(cartProduct));
}
empty_whole_cart()
{
  const cartProduct = [];
  this.setState({cartProduct:cartProduct});
  localStorage.setItem("cartProduct", JSON.stringify(cartProduct));
}
logout(){
sessionStorage.setItem("userData",'');
localStorage.setItem("userData",'');
sessionStorage.clear();
localStorage.clear();
this.setState({redirectToReferrer: true},function(){
});
this.setState({showLogin:'yes'},function(){
})

}
  render() {
     const {cartProduct,prefix,suffix,decimalValue} = this.state;
    let discountValue = 0;
    const discount_type = localStorage.getItem('dis_type');
    const discount_amount = localStorage.getItem('discount');
    if(discount_type === '1')
    {
      discountValue = discount_amount/100;
    }
    else{
      discountValue = discount_amount;
    }

    let sign = '';
    if(discount_type === '1')
    {
      sign = '%';
    }
    else{
      sign = this.state.suffix;
    }

    const { editData } = this.state;
    const { btnLoading } = this.state;
    let dvalue = 0;
    const cproducts = this.state.cartProduct;
    let selectedId = "";
    function fProducts(cproducts, id) {
      let selectedData = cproducts.filter((item) => item.name === id);
      var qty = 0;
      if (selectedData[0]) {
        qty = selectedData[0].qty;
      }

      return qty;
    }

    function fRupees(cproducts, id) {
      let selectedData = cproducts.filter((item) => item.name === id);
      var priceValues = 0;
      if (selectedData[0]) {
        const totalAmount = selectedData[0].qty * selectedData[0].unit_price;
        let show_value = 0;
        if(discount_type==='1')
        {
        show_value = (totalAmount * discountValue);
        priceValues = parseFloat(totalAmount) - parseFloat(show_value);
        }
        else
        {
            show_value = ((totalAmount) - (discountValue*selectedData[0].qty));
            priceValues =(show_value);
        }

      }

      return prefix+priceValues.toFixed(decimalValue)+suffix;
    }


   function grandTotalAmount() {
      var ActualValue = 0;
      var printValue = 0;
      var totalprice = 0;
      var discountValue = 0;
      var totalQty = 0;
      cartProduct.map(value=>{
        var qty = value.qty
        var unit_price = value.unit_price;
        totalprice += parseFloat(unit_price * qty);
        totalQty += value.qty;
      });

      const discount_type = localStorage.getItem('dis_type');
       const discount_amount = localStorage.getItem('discount');
       if(discount_type === '1')
       {
         discountValue = (totalprice *discount_amount/100);
         ActualValue = totalprice - discountValue;
       }
       else{
         discountValue = discount_amount;
         ActualValue = totalprice - (discountValue*totalQty);
       }


      return ActualValue;
    }

     function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const productDetails = this.state.products.map((p, ind) => {
      return (
         <li key={ind} >

               <div className="product_box">
               <a id={`popup_cart_${p.id}`} className={`popup_cart popup_cart_${p.id}`} onClick={()=>this.showPopup(p.id)}>
                  <h4>
                    {p.productName +
                      "" +
                      (p.UOMName ? " - (" + p.UOMName + ")" : "") +
                      ""}
                  </h4>
                  <b>
                     Unit Price: {numberWithCommas(p.unit_price)}
                  </b>


                  <div className="shdown_box">
                     <img className="product_label" src={this.state.img_url + `/${p.image_name}`}/>
               </div>
                </a>

                  <div className="quantity_box_pos">


                    Stock: {parseFloat(p.remainingStock).toFixed(2)}{"("+p.UOMName+")"}


                  </div>
               </div>

         </li>
      );
    });


    const popupProducts = this.state.products.map((p, ind) => {
      let defaultQty = 0;
      this.state.cartProduct.map(value=>{
        if(p.id==value.name)
        {
          defaultQty = value.qty;
        }
        //console.log(this.state.cartProduct)
      })
      return (
         <div key={ind} className={`btm_slide_popup btm_slide_popup_${p.id}`}>
                 <div className="close_popup">
                   <img src={close_popup} alt=""/>
                 </div>
                 <div className="inner_qty_box">
                   <input type="text" autoFocus="true" defaultValue="" size={p.id} placeholder="Enter Qty" onChange={this.changeEditQty} className="edit_product" id={`edit_product_${p.id}`} />
                   <h3>
                    {p.productName +
                      "" +
                      (p.UOMName ? " - (" + p.UOMName + ")" : "") +
                      ""}
                  </h3>
                  <b data-unit={p.unit_price} id={`edit_unit_total_${p.id}`}>
                    {this.state.prefix+parseFloat(p.unit_price).toFixed(this.state.decimalValue)+this.state.suffix}
                  </b>

                     <a className="done_btn" onClick={()=>this.addItemToCart(p.id,p.UOMId,p.UOMName,p.unit_price,p.productName, p.is_stockable,p.remainingStock)}>Add to cart</a>

                 </div>
               </div>
      );
    });

    if (this.state.redirectToReferrer) {
      return <Redirect to={`/`} />;
    }
    // if (localStorage.getItem('order_no')==null) {
    // return (<Redirect to={`/item-selection`}/>)
    // }

    if (this.state.redirect) {
      return <Redirect to={`/pos-invoice/${localStorage.getItem('userData')}`} />;
    }

    return (
<React.Fragment>
<div className="dashboard_body" id="pos_dashb">
   <div className="pos_main">
      <div className="pos_black_box">
         <div className="row">
            <div className="product_btn">
               <img src={iconBullet} alt=""/>
            </div>
            <div className="col-sm-2 logo_box">
            <NavLink to="/dashboard"><img src={logo} alt=""/></NavLink>
            {
            //   <div className="edit_form_data">

            // <NavLink to="/saleorder"><img src={editform} alt=""/></NavLink>
            // </div>
            }
         </div>
         <div className="col-sm-10 search_box">
               <svg  viewBox="0 0 24 24"><path d="M9.5 4a6.5 6.5 0 0 1 4.932 10.734l5.644 5.644l-.707.707l-5.645-5.645A6.5 6.5 0 1 1 9.5 4zm0 1a5.5 5.5 0 1 0 0 11a5.5 5.5 0 0 0 0-11z" fill="#626262"/></svg>
               <input
                  type="text"
                  id="search_here_something"
                  className="form-control"
                  placeholder="Search Here"
                  onChange={this.searchItem.bind(this)}
                />
               {
                   // <button type="button">SEARCH</button>
               }
               <div className="close_search"><img onClick={this.clearSearch} src={close_popup} alt=""/></div>
               <div className="logout_btn"><a href="#" onClick={this.logout}><img src={logout} alt=""/></a></div>

         </div>
      </div>
      <div className="row">
         <div className="col-sm-2 sidebar_left">
            <div className="close_btn_product">
               <img src={icon8} alt=""/>
            </div>
            <ul className="tabs">
               <li>
               <a
               className={`tab-link current traget_class_${0}`} name="0" onClick={this.selectedCategory} data-tab="tab-1"><img src={cart} alt=""/>

               All Products

               </a>

               </li>
               {this.state.categories != null ? (
               this.state.categories.map((c, i) => (
               <li key={i} >
                <a
               name={c.id}
                  className={`tab-link traget_class_${c.id}`}
               onClick={this.selectedCategory}><img src={c.icon_image} alt=""/> {c.groupName}
               </a>
               </li>
               ))
               ) : (
               <li>
               <a
               className={`tab-link current traget_class_${0}`} name="0" onClick={this.selectedCategory} data-tab="tab-1"><img src={cart} alt=""/>

               All Products

               </a>

               </li>
               )}
               {
                 // <li className="tab-link" data-tab="tab-3"><img src={grocery} alt=""/> Grocery</li>
                 //  <li className="tab-link" data-tab="tab-4"><img src={Mushrooms} alt=""/> Mushrooms</li>
                 //  <li className="tab-link" data-tab="tab-5"><img src={fruits} alt=""/> Fruits</li>
                 //  <li className="tab-link" data-tab="tab-6"><img src={dried} alt=""/> Dried fruits & nuts</li>
               }

            </ul>
         </div>
         <div className="col-sm-10 contents_right">
            <div id="tab-1" className="tab-content current">
               <div className="products_listing">
                  <ul>
                     {this.state.loading ? (
                         <div style={{textAlign:'center', padding:'73px 0'}}><img src={loaderImage} alt='Loading' /></div>
                       ) : this.state.products != null && this.state.products != "" ? (
                         productDetails
                       ) : (
                         <div className="no_records">No Record Found</div>
                     )}

                       {
                         //<li><button onClick={this.loadMore}>Load More</button></li>
                       }
                  </ul>
               </div>
            </div>
         </div>
      </div>
      <div className="bottom-nav" >
      <div className="fab"><NavLink to="/dashboard">  <img src={homebtn} alt=""/></NavLink></div>




    </div>
   </div>
</div>
</div>




</React.Fragment>
    );
  }
}
export default ProductDetail;


