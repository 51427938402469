import React, { Component } from 'react'; 
import { Redirect } from 'react-router-dom'; 
class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      redirectToReferrer: false 
    }; 
    this.logout = this.logout.bind(this);
  } 
  logout() {
    sessionStorage.setItem("userData",'');
    localStorage.setItem("userData",'');
    sessionStorage.removeItem("userData");
    localStorage.removeItem("userData");

    this.setState({ redirectToReferrer: true }, function () {
    });
    this.setState({ showLogin: 'yes' }, function () {
    })

  }
  render() { 
    if (this.state.redirectToReferrer) { 
      return (<Redirect to={`/`} />) 
    }else{
      return (<a onClick={this.logout()}></a>)
    }
  }
}
export default Logout;
