import React, { Component } from "react";
import $ from "jquery";
import '../assets/css/bootstrap.css';
import '../assets/css/bootstrap.min.css';
import '../assets/css/pos.css';
import '../assets/css/font-awesome.min.css'; 
import logo from "../assets/images/logo-eloerp.png";
import orderlist from "../assets/images/orderlist.png";
import pos from "../assets/images/pos.png";
import invoicelist from "../assets/images/invoicelist.png";
import producticon from "../assets/images/producticon.png";
import cashbook from "../assets/images/cashbook.png";
import neworder from "../assets/images/neworder.png";
import logout from "../assets/images/icons8-logout-rounded-down-96.png";
import cashreceive from "../assets/images/cashreceiveing.png";
import money from "../assets/images/expenses-money.png"; 
import { Link, NavLink } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { api_url } from "../Config.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      redirectToReferrer: false,
      userBalance: [],
      user_id: localStorage.getItem("userData"),
      base_url: api_url.base_url,
      img_url: api_url.img_url,
      prefix: "",
      suffix: "",
      btnLoading: false,
      editData: [],
      decimalValue: 0,
      account_balance: null

    };
    this.logout = this.logout.bind(this);
  }


  componentDidMount() {
    $('#recButton').addClass("notRec");

    $('#recButton').click(function () {
      if ($('#recButton').hasClass('notRec')) {
        $('#recButton').removeClass("notRec");
        $('#recButton').addClass("Rec");
      }
      else {
        $('#recButton').removeClass("Rec");
        $('#recButton').addClass("notRec");
      }
    });


    // localStorage.clear();
    // sessionStorage.clear();
    localStorage.removeItem('posId');
    localStorage.removeItem('is_edit');
    localStorage.removeItem('posProduct');
    localStorage.setItem("customer_id", 1);
    const prefixurl = this.state.base_url + "sale_api/GetPrefix/" + this.state.user_id;
    fetch(prefixurl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            prefix: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
    const decimalsUrl = this.state.base_url + "sale_api/getDecimals/" + this.state.user_id;
    fetch(decimalsUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            decimalValue: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
    const sufixurl = this.state.base_url + "sale_api/GetSuffix/" + this.state.user_id;
    fetch(sufixurl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            suffix: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
    let user_id = localStorage.getItem('userData');
    const userBalanceApi = this.state.base_url + "sale_api/userSaleDetail/" + this.state.user_id;
    fetch(userBalanceApi)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            userBalance: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );

    // const getCategoriesNames = this.state.base_url+'report_api/accountLedgerTotals';
    //     fetch(getCategoriesNames,
    //     {
    //         method: 'POST',
    //         headers:
    //         {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json',
    //         },
    //         body:JSON.stringify({user_id:this.state.user_id})
    //     })
    //     .then((response) => response.json()
    //         .then((result) => {
    //           console.log(result)
    //            this.setState({
    //              account_balance:result
    //            })
    //     }))
    //     .catch((error) => {
    //         this.setState({loading:false})
    //     });
  }

  componentWillMount() {
    if (sessionStorage.getItem("userData")) {
      this.getUserData();
    } else if (localStorage.getItem("userData")) {
      this.getUserData();
    } else {
      this.setState({ redirectToReferrer: true });
    }
  }
  getUserData() {
    JSON.parse(sessionStorage.getItem("userData"));
    JSON.parse(localStorage.getItem("userData"));
  }
  logout() {
    sessionStorage.setItem("userData", '');
    localStorage.setItem("userData", '');

    this.setState({ redirectToReferrer: true }, function () {
    });
    this.setState({ showLogin: 'yes' }, function () {
    })

  }
  render() {
    // window.location.reload(true)

    if (this.state.redirectToReferrer) {
      return <Redirect to={`/`} />;
    }
    if (this.state.redirect) {
      if (localStorage.getItem("orderId")) {
        return (
          <Redirect
            to={`/product-detail/${localStorage.getItem("orderId")}`}
          />
        );
      } else {
        return <Redirect to={`/product-detail`} />;
      }
    }

    return (
      <div>
        <div className="dashboard_body">
          <div className="pos_main">
            <div className="pos_black_box">
              <div className="row" id="dashb_p">
                <div className="col-sm-2 logo_box">
                  <NavLink to={`/dashboard/${localStorage.getItem('userData')}`}><img src={logo} alt="" /></NavLink>
                </div>
                <div className="col-sm-10 search_box">
                  <div className="head_title logout_head">
                    <div className="logout_btn">
                      {/* <a href="#" onClick={this.logout}><img src={logout} alt="" /></a> */}
                      <Link to="/logout"><img src={logout} alt="" /></Link>
                      <button id="recbtn" class="Rec dashboard_record">Recording</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pos_dashboard boxes_pos">
                <div className="account_bln">
                  <div className="balance_box">
                    {
                      this.state.userBalance.account_balance &&
                      <b>{this.state.prefix + this.state.userBalance.account_balance + this.state.suffix}</b>
                    }

                    <p>Account Balance</p>
                  </div>
                  <div className="row cash_flow">
                    <div className="col-sm-12 cashflow_month cash_box">
                      <h4>Cash Flow</h4>
                    </div>
                    <div className="col-sm-4 cashflow_month">
                      {
                        this.state.userBalance.total_sales_of_day &&
                        <b className="red_value">{this.state.prefix + this.state.userBalance.total_sales_of_day + this.state.suffix}</b>
                      }
                      <p>Sale of day</p>
                    </div>
                    <div className="col-sm-4 cashflow_month">
                      {
                        this.state.userBalance.total_sales_of_month &&
                        <b className="gren_value">{this.state.prefix + this.state.userBalance.total_sales_of_month + this.state.suffix}</b>
                      }
                      <p>Sale of month</p>
                    </div>
                    <div className="col-sm-4 cashflow_month">
                      {
                        this.state.userBalance.avg_sale &&
                        <b>{this.state.prefix + this.state.userBalance.avg_sale + this.state.suffix}</b>
                      }
                      <p>Avg sale</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3 dashboard_icon">
                    <NavLink to={`/new-selection/${localStorage.getItem('userData')}`}>
                      <div className="inner_boxes">
                        <img src={neworder} alt="" />
                        <b>New Order</b>
                      </div>
                    </NavLink>
                  </div>
                  <div className="col-sm-3 dashboard_icon active_dash_b">
                    <NavLink to={`/pos/${localStorage.getItem('userData')}`}>
                      <div className="inner_boxes">
                        <img src={pos} alt="" />
                        <b>Point of Sale</b>
                      </div>
                    </NavLink>
                  </div>
                  <div className="col-sm-3 dashboard_icon">
                    <NavLink to={`/order-listing/${localStorage.getItem('userData')}`}>
                      <div className="inner_boxes">
                        <img src={orderlist} alt="" />
                        <b>Orders List</b>
                      </div>
                    </NavLink>
                  </div>
                  <div className="col-sm-3 dashboard_icon">
                    <NavLink to={`/pos-listing/${localStorage.getItem('userData')}`}>
                      <div className="inner_boxes">
                        <img src={invoicelist} alt="" />
                        <b>Invoices List</b>
                      </div>
                    </NavLink>
                  </div>
                  <div className="col-sm-3 dashboard_icon">
                    <NavLink to={`/products-detail/${localStorage.getItem('userData')}`}>
                      <div className="inner_boxes">
                        <img src={producticon} alt="" />
                        <b>Products</b>
                      </div>
                    </NavLink>
                  </div>
                  {/* <div className="col-sm-3 dashboard_icon">
                    <NavLink to={`/dashboard/${localStorage.getItem('userData')}`}>
                      <div className="inner_boxes">
                        <img src={cashbook} alt="" />
                        <b>Cash Book</b>
                      </div>
                    </NavLink>
                  </div> */}
                  <div className="col-sm-3 dashboard_icon">
                    <NavLink to={`/cash-receive/${localStorage.getItem('userData')}`}>
                      <div className="inner_boxes">
                        <img src={cashreceive} alt="" />
                        <b>Cash Receiving</b>
                      </div>
                    </NavLink>
                  </div>
                  <div className="col-sm-3 dashboard_icon">
                    <NavLink to={`/expense/${localStorage.getItem('userData')}`}>
                      <div className="inner_boxes">
                        <img src={money} alt="" />
                        <b>Expenses</b>
                      </div>
                    </NavLink>
                  </div> 
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Dashboard;
