import React, { Component } from 'react';
import Header from "./Header.js"; 
import {Redirect} from 'react-router-dom'; 
import {api_url} from '../Config.js';
class ForgetPassword extends Component {
	constructor(props){
super(props);
this.state = {

redirectToReferrer: false,
empty_num:null,
keeplogin:false,
changePass:null,
base_url:api_url.base_url,
img_url:api_url.img_url,
number:'',
 loading: false,
 user_id: JSON.parse(sessionStorage.getItem("userData"))
        ? JSON.parse(sessionStorage.getItem("userData"))
        : JSON.parse(localStorage.getItem("userData")),
};
this.changePassword = this.changePassword.bind(this)
}

changePassword(e){
if(this.state.changePass==null)
{
this.setState({number:'Please enter your mobile number'})
}else{

 let number =  this.state.changePass
 this.setState({ loading: true });
const apiUrl = this.state.base_url+'sale_api/verifyNumber';
    fetch(apiUrl+'?number='+number)
    
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            number: result,
            loading:false,
          });
         if(this.state.number === number)
  {
    this.setState({redirectToReferrer:true})
  }else{
    this.setState({redirectToReferrer:false})
  }
        },
        (error) => {
          this.setState({ error });
        }
      )
}
  
}

componentDidMount(){

    const apiUrl = this.state.base_url+`sale_api/getCompanyLogo/${this.state.user_id}`;
    fetch(apiUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            company_logo: result
          });
          
        },
        (error) => {
          this.setState({ error });
        }
      )

  }

render() {
  const { loading } = this.state;
if (this.state.redirectToReferrer) {
return (<Redirect to={`/change-password/${localStorage.getItem('userData')}`}/>)
}
		return (
<div>
<Header />	

<div className="main_body">
  <div className="login_screen" id="uder_eng_language">
    <div className="logo login_page">
      <img src={this.state.img_url+this.state.company_logo} alt="logo"/>
    </div>
    
    <div className="user_login">
      <div className="form_box">
        <input type="text" placeholder="Mobile Number" name="changepass" onChange={(event)=>this.setState({changePass:event.target.value})} />
       <p className="error_box">{this.state.number}</p>
      </div>
      
      <div className="form_box" id="login_items">
       
      
        <button className="button-loadings" style={{'fontSize':'18px', width:'100%'}} type="button" onClick={this.changePassword} disabled={loading}>
          {loading && (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />
          )}
          {loading && <span>Loading...</span>}
          {!loading && <span>Change Password</span>}
        </button>

      </div>

      </div>
   
  
  </div>
</div>
</div>
		);
	}
}
export default ForgetPassword;