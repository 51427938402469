import React, { Component } from "react";
import $ from "jquery"; 
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { api_url } from "../Config.js"; 
import save from "../assets/images/save_icon.png";
import print from "../assets/images/print-icon.png";
import email from "../assets/images/email-icon.png";
import empty_card from "../assets/images/shopping-basket.png";
import  "../assets/css/thermal.css";
import ReactToPrint from 'react-to-print';
import swal from "sweetalert";
import ThermalInvoice from './ThermalInvoice';
class PosInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
        invoiceNo:this.props.match.params.invoiceId,
        items: null,
        redirectToReferrer: false,
        user_id: localStorage.getItem("userData"),
        base_url: api_url.base_url,
        total_amount: 0,
        order_no: 0,
        posProduct:[],
         email: "",
         redirect: false,
         redirectBack:false,
         loading: false,
        order_no: 0,
        totalorder: 0,
        discount: 0,
        dis_type: 0,
        customer_id: 0,
        order_type: 0,
        user_id: JSON.parse(sessionStorage.getItem("userData"))
        ? JSON.parse(sessionStorage.getItem("userData"))
        : JSON.parse(localStorage.getItem("userData")),
         status: "Pending",
         editData: [],
         suffix:'',
         decimalValue:0,
         orderToDetails:[],
         saleMan:[],
         orderType:[],
         orderDetails:[],
         printDoc:false,
         masterData:[],
         returnTotalAmount:0,
         returnItemArray:[],
         buttonLoading:false
      };
   }

   componentDidMount() { 
   const requiredURL = this.state.base_url + "sale_api/getAllAboutTheOrder/"+this.state.customer_id+"/"+this.state.user_id+"/"+this.state.order_type+"/"+this.state.order_no+"/"+this.state.user_id
    fetch(requiredURL)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            orderToDetails: result.customer,
            orderType: result.ordertype,
            saleMan: result.users,
            orderDetails:result.orderDetails
          });
        },
        (error) => {
          this.setState({ error });
        }
      );

      const getInvoiceDetail = this.state.base_url + "sale_api/getInvoice/"+this.state.user_id+"/"+this.state.invoiceNo;
    fetch(getInvoiceDetail)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            masterData: result.model.data,
            items:result.items,
            posProduct:result.items
          });
        },
        (error) => {
          this.setState({ error });
        }
      );

    const decimalsUrl = this.state.base_url + "sale_api/getDecimals/"+this.state.user_id;
    fetch(decimalsUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            decimalValue: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
      const sufixurl = this.state.base_url + "sale_api/GetSuffix/"+this.state.user_id;
    fetch(sufixurl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            suffix: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );

      $(".process_complete").show();
      $(".last_step").hide();
      $(".footer").hide();
      if (localStorage.getItem("orderId") !== null) {
         const getIdUrl =
            this.state.base_url +
            "sale_api/getBySaleOrder/" +
            localStorage.getItem("orderId");
         fetch(getIdUrl)
            .then((res) => res.json())
            .then(
               (result) => {

                  this.setState(
                     {
                        editData: result.basic_details,
                        loading: false,
                        status: result.basic_details.saleOrderStatus,
                     },
                     function () {
                        
                     }
                  );
               },
               (error) => {}
            );
      }
   }
   componentWillUnmount(){
    localStorage.removeItem("paid_amount");
   }
   componentWillMount() {
      if (sessionStorage.getItem("userData")) {
         this.getUserData();
      } else if (localStorage.getItem("userData")) {
         this.getUserData();
      } else {
         this.setState({ redirectToReferrer: true });
      }
   }
   getUserData() {
      JSON.parse(sessionStorage.getItem("userData"));
      JSON.parse(localStorage.getItem("userData"));
   }
   save_records() {
    this.setState({buttonLoading:true});
        var productsArray = Array(); 
        var grandTotalAmount = 0;
            this.state.returnItemArray.map(value=>{
                const orignalArray = this.state.items.filter(
                      (item) => item.product_id === value.name
                    );
                grandTotalAmount += parseFloat(value.qty) * parseFloat(value.unit_price);
               return(
                     productsArray.push({
                        'detail_id':'',
                        'invoiceNo':this.state.invoiceNo,
                        'product_id':value.name,
                        'ware_house_id':localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : 1,
                        'name':value.pname,
                        'below_cost_discount':'',
                        'discount':'',
                        'discount_amount':'',
                        'productCode':orignalArray[0].productCode,
                        'barcode':'',
                        'batchNo':'',
                        'UOMId':value.uom,
                        'unitName':value.uomName,
                        'qty':orignalArray[0].qty,
                        'meter_qty':'',
                        'remainingQty':value.qty,
                        'returnQty':value.qty,
                        'salePrice':value.unit_price,
                        'extraNotes':'',
                        'taxAmount':'',
                    })
                );
            });
        var masterData = {
           'invoiceNo':this.state.invoiceNo,
           'customerId':this.state.masterData.customer_id ? this.state.masterData.customer_id :'',
           'salesmanId':this.state.masterData.sale_man_id ? this.state.masterData.sale_man_id :'',
           'sale_man_id':this.state.masterData.sale_man_id ? this.state.masterData.sale_man_id :'',
           'payment_method_id':'',
           'shipping_cost':'',
           'extra_charges':'',
           'interest_amount':'',
           'discount_amount':'',
           'discount_type':'',
           'lead_id':'',
           'deliveryDate':this.state.masterData.created_on ? this.state.masterData.created_on :'',
           'currencyId':'',
           'tax_amount':'',
           'narration':'',
           'subtotal':'',
           'discount':'',
           'freight':'',
           'tax':'',
           'tax_name':'',
           'totalDownPayment':'',
           'payment_receive':'',
           'balance':'',
           'total':grandTotalAmount,
           'created_on':this.state.masterData.created_on ? this.state.masterData.created_on :'',
           'pos_type':'Cash',
            };
            
             
            const sendData={
                total:grandTotalAmount,
                subtotal:grandTotalAmount,
                balance:'',
                invoiceNo:this.state.invoiceNo,
                items:productsArray,
                invoice:masterData,
                user_id:this.state.user_id
            };

            const saveData = this.state.base_url+'sales/returnInvoice';
            fetch(saveData,
            {
                method: 'POST',
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify(sendData)
            })
            .then((response) => response.json()
                .then((result) => {
                 this.setState({
                  buttonLoading:false,
                  redirectBack:true
                 });
                 swal('Return invoice created successfully')
            }))
            .catch((error) => {
              swal('Error Occured please try again')
                this.setState({
                  buttonLoading:false,
                  redirectBack:false
                 });

                

            });
                
   }
   printDocument() {
        var productsArray = Array(); 
        var grandTotalAmount = 0;
            this.state.returnItemArray.map(value=>{
                const orignalArray = this.state.items.filter(
                      (item) => item.product_id === value.name
                    );
                grandTotalAmount += parseFloat(value.qty) * parseFloat(value.unit_price);
               return(
                     productsArray.push({
                        'detail_id':'',
                        'invoiceNo':this.state.invoiceNo,
                        'product_id':value.name,
                        'ware_house_id':localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : 1,
                        'name':value.pname,
                        'below_cost_discount':'',
                        'discount':'',
                        'discount_amount':'',
                        'productCode':orignalArray[0].productCode,
                        'barcode':'',
                        'batchNo':'',
                        'UOMId':value.uom,
                        'unitName':value.uomName,
                        'qty':orignalArray[0].qty,
                        'meter_qty':'',
                        'remainingQty':value.qty,
                        'returnQty':value.qty,
                        'salePrice':value.unit_price,
                        'extraNotes':'',
                        'taxAmount':'',
                    })
                );
            });
        var masterData = {
           'invoiceNo':this.state.invoiceNo,
           'customerId':this.state.masterData.customer_id ? this.state.masterData.customer_id :'',
           'salesmanId':this.state.masterData.sale_man_id ? this.state.masterData.sale_man_id :'',
           'sale_man_id':this.state.masterData.sale_man_id ? this.state.masterData.sale_man_id :'',
           'payment_method_id':'',
           'shipping_cost':'',
           'extra_charges':'',
           'interest_amount':'',
           'discount_amount':'',
           'discount_type':'',
           'lead_id':'',
           'deliveryDate':this.state.masterData.created_on ? this.state.masterData.created_on :'',
           'currencyId':'',
           'tax_amount':'',
           'narration':'',
           'subtotal':'',
           'discount':'',
           'freight':'',
           'tax':'',
           'tax_name':'',
           'totalDownPayment':'',
           'payment_receive':'',
           'balance':'',
           'total':grandTotalAmount,
           'created_on':this.state.masterData.created_on ? this.state.masterData.created_on :'',
           'pos_type':'Cash',
            };
            
             
            const sendData={
                total:grandTotalAmount,
                subtotal:grandTotalAmount,
                balance:'',
                invoiceNo:this.state.invoiceNo,
                items:productsArray,
                invoice:masterData,
                user_id:this.state.user_id
            };

            const saveData = this.state.base_url+'sales/returnInvoice';
            fetch(saveData,
            {
                method: 'POST',
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify(sendData)
            })
            .then((response) => response.json()
                .then((result) => {
                 this.setState({
                  buttonLoading:false,
                  redirectBack:true
                 });
                 swal('Return invoice created successfully')
            }))
            .catch((error) => {
              swal('Error Occured please try again')
                this.setState({
                  buttonLoading:false,
                  redirectBack:false
                 });

                

            });
                
   }
   save_records_email() {
   }

   printDocumentposwala() {
 
     var dis_type = localStorage.getItem('dis_type');
      if (this.state.items === null) {
         swal("Please select some products");
      } else {

          
        
        var invoice = {
          
      }

 


         const postData = this.state.base_url+'sales/checkout';
            fetch(postData,
            {
                method: 'POST',
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify(invoice)
            })
            .then((response) => response.json()
                .then((result) => {
                 
            }))
            .catch((error) => {
              

            });


      }

   }
   onDocumentLoadSuccess(numPages) {
      this.setState({ numPages });
   }

   async increaseQty(selectedItem,id ,uomId, UOMName , unit_price , name, is_stockable) { 
    var product_name = name;
    var uomId = uomId ? uomId : "";
    var uomName = UOMName ? UOMName : "";
    var unit_price = unit_price;
    var product_id = id;
    var is_stockable = is_stockable ? is_stockable :0;

    var returnProduct = {
      name: product_id,
      qty: 1,
      unit_price: unit_price,
      pname: product_name,
      uom: uomId,
      uomName: uomName,
      is_stockable:is_stockable
    };   

    if (this.state.returnItemArray.length>0) {
        var upQty = 1;  
        const updateQtyArray = this.state.returnItemArray.filter(
          (item) => item.name === product_id
        );
        if (updateQtyArray.length>0) {
            upQty=updateQtyArray[0].qty + parseFloat(1);
        } 
        if (upQty <= selectedItem.qty) {
            
            const updatedItemArray = this.state.returnItemArray.filter(
              (item) => item.name !== product_id
            ); 
            var upQtyArry = {
              name: product_id,
              qty:  parseFloat(upQty),
              unit_price: unit_price,
              pname: product_name,
              uom: uomId,
              uomName: uomName,
              is_stockable:is_stockable
            }; 
            this.setState({returnItemArray:updatedItemArray},()=>{

                this.setState({returnItemArray:[...this.state.returnItemArray, upQtyArry]});
            });
        }else{
            swal('Quantity value is too high.');
        }
    }else{
         
        this.setState({returnItemArray:[...this.state.returnItemArray, returnProduct]}); 
    }
      
  }

  async decreaseQty(id ,uomId, UOMName , unit_price , name, is_stockable) {
   
    var product_name = name;
    var uomId = uomId ? uomId : "";
    var uomName = UOMName ? UOMName : "";
    var unit_price = unit_price;
    var product_id = id;
    var is_stockable = is_stockable ? is_stockable :0;

    this.state.returnItemArray.map((c, i) => {
      if (c.name == product_id) {
        if (c.qty > 1) {
          const olvalue = c.qty;
          const olproduct = [...this.state.returnItemArray];
          const delit = olproduct.filter((item) => item.name !== product_id);
          this.setState(
            {
              returnItemArray: delit,
            },
            function () {
             
              const newproduct= {
                    name: product_id,
                    qty: parseFloat(olvalue) - parseFloat(1),
                    unit_price: unit_price,
                    pname: product_name,
                    uom: uomId,
                    uomName: uomName,
                    is_stockable:is_stockable
              };
               this.setState({returnItemArray:[...this.state.returnItemArray,newproduct]}) 
            }
          );
        } else {
          const oproduct = [...this.state.returnItemArray];
          const dit = oproduct.filter((item) => item.name !== product_id);
          this.setState(
            {
              returnItemArray: dit,
            } 
          );
        }
      }
    });
  }



   render() {
     const {posProduct,returnItemArray} = this.state;
        const date = new Date();
        var d = date.getDate();
        var m = date.getMonth();
        var y = date.getFullYear();
        var month = m+parseFloat(1);
        var formatedDate = y+'-'+month+'-'+d;
        var time = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
        const mydate = (this.state.orderDetails) ? this.state.orderDetails.created_on : formatedDate;
        let discountValue = 0;
        const discount_type = localStorage.getItem('dis_type');
        const discount_amount = localStorage.getItem('discount');
        if(discount_type === '1')
        {
          discountValue = discount_amount/100;
        }
        else{
          discountValue = discount_amount;
        }

        let sign = '';
        if(discount_type === '1')
        {
          sign = '%';
        }
        else{
          sign = this.state.suffix;
        } 
      const items = this.state.items; 
      function fProducts(cproducts, id) {
          let selectedData = cproducts.filter((item) => item.name === id);
          var qty = 0;
          if (selectedData[0]) {
            qty = selectedData[0].qty;
          }

          return qty;
        }
        const itemArray = this.state.returnItemArray;
      function printPrice(item)
      { 
        let pricevalue = 0;
        for (var i = 0; i < itemArray.length; i++) {
            var selectpro = itemArray[i];

            if(item.product_id===selectpro.name){
                pricevalue  = selectpro.unit_price * selectpro.qty;
            }
        }
        

          return pricevalue;
      }

      function grandTotalAmount() {
      var ActualValue = 0;
      var printValue = 0;
      var totalprice = 0;
      var discountValue = 0;
      var totalQty = 0;
      if (items!==null) {
        items.map(value=>{
            var qty = value.qty
            var unit_price = value.saleRate;
            totalprice += parseFloat(unit_price * qty);
            totalQty += value.qty;
          });
      }

      const discount_type = localStorage.getItem('dis_type');
       const discount_amount = localStorage.getItem('discount');
       if(discount_type === '1')
       {
         discountValue = (totalprice *discount_amount/100);
         ActualValue = totalprice - discountValue;
       }
       else{
         discountValue = discount_amount;
         ActualValue = totalprice - (discountValue*totalQty);
       }


      return ActualValue;
    }

    const deci = this.state.decimalValue;
    function numberWithCommas(x) {
        return Number(parseFloat(x).toFixed(deci)).toLocaleString('en', {
            minimumFractionDigits: deci
        });
    }

      if (this.state.redirectToReferrer) {
         return <Redirect to={`/`} />;
      }
      if (this.state.redirectBack) {
         return <Redirect to={`/pos-listing/${localStorage.getItem('userData')}`} />;
      }

      return (
         <div>
             <div style={{display:'none'}}>
                 <ThermalInvoice ref={el => (this.componentRef = el)} />
             </div>
            <div id="invoice_header">

            <div className="main_body" id="invoice_listing_p">
               <div className=" order_lists" id="order_lists_load">
                  <div className="user_login Select_language process_complete">
                     <div className="form_box select_items_pdt">
                        <h3 style={{ marginBottom: "0px" }}>Invoice # {this.state.invoiceNo} <Link className="angle_back" to={`/pos-listing/${localStorage.getItem('userData')}`}><i className="fa fa-angle-left"></i></Link> </h3>
                      <div className="head_amount">
                      <b>{this.state.prefix}{numberWithCommas(grandTotalAmount())}{this.state.suffix}</b>
                      <p>
 

                      {
                        this.state.orderToDetails.ledgerName != '' &&
                        <p><b>{this.state.orderToDetails.ledgerName}</b></p>
                      }
                      </p>
                      </div>
                     </div>
                     <div className="view_history_inv">
                        <h4>Invoice created for {this.state.prefix}{numberWithCommas(grandTotalAmount())}{this.state.suffix} </h4>
                        <p>{this.state.masterData.created_on}</p>
                     </div>
                     <div className="main_row">
                      <div className="col-8- invoice-left-right padd-left- p-padd invoice-space">
                        <ul>

                            <li><span>Invoice #</span> <b>SI-{this.state.invoiceNo}</b></li>
                            <li><span>Invoice Date</span> <b>{this.state.masterData.created_on}</b></li>
                            <li><span>To</span> <b>{this.state.masterData.ledgerName}</b></li>
                        </ul>
                      </div>


                     </div>
                     <div className="pos_inv_table ">


                      {items != null ? (
                         items.map((item, index) => {
                           var UOMNAME = '';
                           if (item.UOMName !='') {
                             UOMNAME = '('+item.UOMName+')'
                           }
                           var grey_class = '';
                           if (index%2!=0) {
                             grey_class = 'gray_bg_box';
                           }
                           return(
                            <div key={index} className={`row invoice_bdr ${grey_class} overdue onclic_row`}>
                              <div className="col-sm-6 left_invoice">
                                <h4>{item.productName}</h4>
                                <p>{numberWithCommas(item.qty)} {UOMNAME}  x {numberWithCommas(item.saleRate) }</p>
                                {
                                  this.state.discount > 0 &&
                                  <p>Discount : {this.state.discount} {sign}</p>
                                }

                              </div>
                              
                              <div className="col-sm-6 right_invoice" id="return_qty">

                              

                                <div className="quantity_box_pos">
                                   <input type="button" value="-" className="qty-minus_pos minus_plus_pos" onClick={()=>this.decreaseQty(item.product_id,item.UOMId,item.UOMName,item.saleRate,item.productName, item.is_stockable)}/>
                                   <input type="text" className="value_box_pos" value={fProducts(returnItemArray, item.product_id)}/>
                                   <input type="button" value="+" className="qty-plus_pos minus_plus_pos" onClick={()=>this.increaseQty(item,item.product_id,item.UOMId,item.UOMName,item.saleRate,item.productName, item.is_stockable)}/>
                                </div>
                                {/*<h4>{this.state.prefix} { numberWithCommas(printPrice(item) } </h4>*/}

                                <h4>{this.state.prefix} {numberWithCommas(printPrice(item))}</h4>

                              </div>
                            </div>
                         )
                         })
                      ) : (
                         <tr>
                            <td colSpan="12">
                               <div className="empty_cart_bx">
                               <img src={empty_card} alt=""/>
                               </div>
                            </td>
                         </tr>
                      )}

                    </div>
                    <div id="invoice_listing_ftr" className="total_btm-bar" style={{ padding: "0px" }} >
                        <div className="total-amount">
                            {
                                this.state.buttonLoading ?
                                <div className='save_data'>
                                    <p>Saving Invoice...</p>
                                    <i
                                        className="fa fa-refresh fa-spin"
                                        style={{ marginRight: "5px" }}
                                     />
                                </div>
                                :                        
                                <>
                               <button
                                  className="request_door_click"
                                  type="button"
                                  onClick={this.save_records.bind(this)} 
                                >
                                    <span><img  src={save} /> Save</span>
                                </button>
                                <ReactToPrint
                                    onBeforePrint={this.printDocument.bind(this)}
                                    trigger={() => {

                                    return (
                                            <button
                                              className="save-print request_door_click"
                                              type="button"
                                           >
                                              
                                              <span><img  src={print} /> Save & Print</span>
                                           </button>
                                        );
                                  }}
                                  content={() => this.componentRef}
                                />

                                <button
                                  className="eamil-print request_door_click"
                                  type="button"
                                  onClick={this.save_records.bind(this)} 
                                >
                                    
                                  <span><img  src={email} /> Save & Email</span>
                               </button>
                            </>
                        
                            }
                        </div>
                    </div>
                  </div>
               </div>
            </div>
             
             
         </div>
             
             
         </div>
      );
   }
}

export default PosInvoice;
