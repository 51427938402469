import React, { Component } from "react";
import $ from "jquery"; 
import { Redirect } from "react-router-dom"; 
import { api_url } from "../Config.js";  
import empty_card from "../assets/images/shopping-basket.png"; 
import  "../assets/css/thermal.css";
class InvoiceListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
        items: JSON.parse(localStorage.getItem("posProduct")),
        redirectToReferrer: false,
        user_id:localStorage.getItem("userData"),
            editData: JSON.parse(sessionStorage.getItem("editData"))
            ? JSON.parse(sessionStorage.getItem("editData"))
            :[],
         base_url: api_url.base_url,
         img_url: api_url.img_url,
         total_amount: localStorage.getItem("total_amount")
            ? localStorage.getItem("total_amount")
            : 0,
         order_no: localStorage.getItem("order_no")
            ? localStorage.getItem("order_no")
            : null,
            posProduct:
        JSON.parse(localStorage.getItem("posProduct")) != null
          ? JSON.parse(localStorage.getItem("posProduct"))
          : [],
         email: "",
         redirect: false,
         loading_: false,
         loading__: false,
         loading___: false,
         loading: false,
         orderId: localStorage.getItem("orderId")
            ? localStorage.getItem("orderId")
            : "",
        discount_type: localStorage.getItem("dis_type")
        ? localStorage.getItem("dis_type")
        : 0,
        order_no: localStorage.getItem("order_no")
        ? localStorage.getItem("order_no")
        : 0,
        totalorder: localStorage.getItem("totalorder")
        ? localStorage.getItem("totalorder")
        : 0,
        discount: localStorage.getItem("discount")
        ? localStorage.getItem("discount")
        : 0,
        dis_type: localStorage.getItem("dis_type")
        ? localStorage.getItem("dis_type")
        : 0,
        customer_id: localStorage.getItem("customer_id")
        ? localStorage.getItem("customer_id")
        : 0,
        order_type: localStorage.getItem("order_type")
        ? localStorage.getItem("order_type")
        : 0,
        user_id: JSON.parse(sessionStorage.getItem("userData"))
        ? JSON.parse(sessionStorage.getItem("userData"))
        : JSON.parse(localStorage.getItem("userData")),

         status: "Pending",
         editData: [],
         suffix:'',
         decimalValue:null,
         orderToDetails:[],
         saleMan:[],
         orderType:[],
         orderDetails:[],
         companyInfo:[],
      };
   }

   componentDidMount() {
   const requiredURL = this.state.base_url + "sale_api/getAllAboutTheOrder/"+this.state.customer_id+"/"+this.state.user_id+"/"+this.state.order_type+"/"+this.state.order_no
    fetch(requiredURL)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            orderToDetails: result.customer,
            orderType: result.ordertype,
            saleMan: result.users,
            orderDetails:result.orderDetails
          });
        },
        (error) => {
          this.setState({ error });
        }
      );


    const decimalsUrl = this.state.base_url + "sale_api/getDecimals/"+this.state.user_id;
    fetch(decimalsUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            decimalValue: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
      const sufixurl = this.state.base_url + "sale_api/GetSuffix/"+this.state.user_id;
    fetch(sufixurl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            suffix: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );

      const getCompanyLogo = this.state.base_url + "sale_api/getCompanyLogo/"+this.state.user_id;
    fetch(getCompanyLogo)
      .then((res) => res.json())
      .then(
        (result) => {

          this.setState({
            companyInfo: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );

      $(".process_complete").show();
      $(".last_step").hide();
      $(".footer").hide();
      if (localStorage.getItem("orderId") !== null) {
         const getIdUrl =
            this.state.base_url +
            "sale_api/getBySaleOrder/" +
            localStorage.getItem("orderId")+"/"+this.state.user_id;
         fetch(getIdUrl)
            .then((res) => res.json())
            .then(
               (result) => {

                  this.setState(
                     {
                        editData: result.basic_details,
                        loading: false,
                        status: result.basic_details.saleOrderStatus,
                     },
                     function () {
                        // localStorage.setItem('total_amount',((result.basic_details.total)).toFixed(0))
                        // console.log(localStorage.getItem('total_amount'))
                     }
                  );
               },
               (error) => {}
            );
      }
   }

   componentWillMount() {
      if (sessionStorage.getItem("userData")) {
         this.getUserData();
      } else if (localStorage.getItem("userData")) {
         this.getUserData();
      } else {
         this.setState({ redirectToReferrer: true });
      }
   }
   getUserData() {
      JSON.parse(sessionStorage.getItem("userData"));
      JSON.parse(localStorage.getItem("userData"));
   }


   render() {
     const {posProduct} = this.state;
        const date = new Date();
        var d = date.getDate();
        var m = date.getMonth();
        var y = date.getFullYear();
        var month = m+parseFloat(1);
        var formatedDate = y+'-'+month+'-'+d;
        var time = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
        const mydate = (this.state.orderDetails) ? this.state.orderDetails.created_on : formatedDate;
        let discountValue = 0;
        const discount_type = localStorage.getItem('dis_type');
        const discount_amount = localStorage.getItem('discount');
        if(discount_type === '1')
        {
          discountValue = discount_amount/100;
        }
        else{
          discountValue = discount_amount;
        }

        let sign = '';
        if(discount_type === '1')
        {
          sign = '%';
        }
        else{
          sign = this.state.suffix;
        }
      const { loading, loading_, loading__, loading___ } = this.state;
      const items = JSON.parse(localStorage.getItem("posProduct"));

      function printPrice(item)
      {
        let pricevalue = 0;
        if(discount_type==='1')
          {
            pricevalue = item.unit_price * item.qty - (item.unit_price * item.qty * discountValue);
          }else{
            pricevalue  = item.unit_price * item.qty - ( item.qty * discountValue);
          }

          return pricevalue;
      }

      function grandTotalAmount() {
      var ActualValue = 0;
      var printValue = 0;
      var totalprice = 0;
      var discountValue = 0;
      var totalQty = 0;
      posProduct.map(value=>{
        var qty = value.qty
        var unit_price = value.unit_price;
        totalprice += parseFloat(unit_price * qty);
        totalQty += value.qty;
      });

      const discount_type = localStorage.getItem('dis_type');
       const discount_amount = localStorage.getItem('discount');
       if(discount_type === '1')
       {
         discountValue = (totalprice *discount_amount/100);
         ActualValue = totalprice - discountValue;
       }
       else{
         discountValue = discount_amount;
         ActualValue = totalprice - (discountValue*totalQty);
       }


      return ActualValue;
    }
    const deci = this.state.decimalValue;
    function numberWithCommas(x) {
        return Number(parseFloat(x).toFixed(deci)).toLocaleString('en', {
            minimumFractionDigits: deci
        });
    }


      if (this.state.redirectToReferrer) {
         return <Redirect to={`/`} />;
      }
      if (this.state.redirect) {
         return <Redirect to={`/pos/${localStorage.getItem("userData")}`} />;
      }
      return (
         <div>
            <div className="thermal_inv">
              <div className="thermal_box">
                <img src={this.state.img_url+this.state.companyInfo.company_logo} alt=""/>
                <b>{this.state.companyInfo.companyName}</b>
                <p>{this.state.companyInfo.address}</p>
                <p>{this.state.companyInfo.web}</p>
              </div>
              <div className="user_info">
                <p><b>Date:</b>{formatedDate+' '+time}</p>
                <p><b>Invoice No:</b>SI-{this.state.totalorder}</p>
                <p><b>Order Type:</b>{localStorage.getItem('order_type')}</p>
                {
                    this.state.orderToDetails.ledgerName!='Cash'
                    ?
                    <React.Fragment>
                    {
                        this.state.orderToDetails.ledgerName != '' &&
                        <p><b>Cash/Customer:</b>{this.state.orderToDetails.ledgerName}</p>

                    }
                    {
                        this.state.orderToDetails.phone != null &&
                        <p><b>Customer Phone:</b>{this.state.orderToDetails.phone} </p>

                    }
                    {
                        this.state.orderToDetails.address != null &&
                        <p><b>Address:</b>{this.state.orderToDetails.address}</p>

                    }


                    </React.Fragment>
                    :
                    ''
                }





              </div>
              <div className="table_invoice">
                <table className="table">
                <thead>
                    <tr>
                      <th>Product</th>
                      <th>Price</th>
                      <th>Qty</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                  {items != null ? (
                         items.map((item, index) => {
                           var UOMNAME = '';
                           if (item.uomName !='') {
                             UOMNAME = '('+item.uomName+')'
                           }
                           return(
                               <tr key={index}>
                                  <td>{item.pname}</td>
                                  <td>{numberWithCommas(item.unit_price)}</td>
                                  <td>{numberWithCommas(item.qty)} {UOMNAME}</td>
                                  <td>{this.state.prefix} {
                                            numberWithCommas(printPrice(item))
                                          }</td>
                                </tr>
                             )
                            })
                      ): (
                         <tr>
                            <td colSpan="12">
                               <div className="empty_cart_bx">
                               <img src={empty_card} alt=""/>
                               </div>
                            </td>
                         </tr>
                      )}

                    </tbody>
                  </table>
              </div>
              <div className="subtotal">
                <p><b>Sub Total</b>{this.state.prefix}{numberWithCommas(grandTotalAmount())}{this.state.suffix}</p>
                <p><b>Grand Total</b>{this.state.prefix}{numberWithCommas(grandTotalAmount())}{this.state.suffix}</p>
                {
                  //<p><b>Amount Paid</b>   0.00</p>
                  //<p><b>Balance</b>   120.00</p>
                }

              </div>
              <div className="thankyou">
                <p>{this.state.companyInfo.invoice_footer_text}</p>
                <p>Software By: IT Vision (Pvt.)Ltd. | <span>www.eloerp.com</span> | <span>+92332510131</span></p>
              </div>
            </div>
         </div>
      );
   }
}

export default InvoiceListing;
