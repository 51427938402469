import React, { Component } from "react";
import axios from "axios";
import Header from "./Header.js"; 
import {Redirect} from 'react-router-dom';
import {api_url} from '../Config.js'; 
import loading  from "../assets/images/loading.gif";
class PosListing extends Component {
  constructor() {
    super();
    this.state = {
      photos: [],
      loading: false,
      page: 0,
      prevY: 0,
      decimalValue:0,
      redirectToReferrer: false,
      user_id:localStorage.getItem("userData"),
      base_url:api_url.base_url,
      img_url:api_url.img_url,
      prefix:'',
      suffix:'',
      redirect:false,
      can_cancel:0,
      can_return:0,
      redirect_to_return:false,
      return_id:0
    };
  }
   componentDidMount() {

    const canRetUrl = this.state.base_url + "sale_api/canCancelReturn/"+this.state.user_id;
    fetch(canRetUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            can_return: result.can_return,
            can_cancel: result.can_cancel
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
    
    const decimalsUrl = this.state.base_url + "sale_api/getDecimals/"+this.state.user_id;
    fetch(decimalsUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            decimalValue: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
    this.getPhotos(this.state.page);

    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };

    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );
    this.observer.observe(this.loadingRef);
  }
    getPhotos(page) {
    this.setState({ loading: true });
    axios
      .get(
        this.state.base_url+`sale_api/pos_listing/${page}/7/${this.state.user_id}`
      )
      .then(res => {
        this.setState({ photos: [...this.state.photos, ...res.data] });
        this.setState({ loading: false });
        this.setState({page:this.state.page+parseFloat(7)})
      },(error) => {this.setState({loading:false})});
  }
handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;
    if (this.state.prevY > y) {
      const lastPhoto = this.state.page;
      const curPage = lastPhoto ;
      this.getPhotos(curPage);
      this.setState({ page: curPage });
    }
    this.setState({ prevY: y });
  }

  componentWillMount() {
if(sessionStorage.getItem("userData")){
this.getUserData();
}else if(localStorage.getItem('userData')){
  this.getUserData();
}
else{
this.setState({redirectToReferrer: true});
}
}
getUserData() {
JSON.parse(sessionStorage.getItem("userData"));
JSON.parse(localStorage.getItem("userData"));

}

returnInvoiceHandler(id)
{
  this.setState({redirect_to_return:true,return_id:id})
}
editData(id){
   if(id!==null)
   {
    localStorage.setItem('posId',id)
    if(localStorage.getItem('posId')!==null)
    {
      this.setState({redirect:true})
    }
   }
}

  render() {
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
const {photos} = this.state;
      // Additional css
    const loadingCSS = {
      height: "100px",
      margin: "0px 30px"
    };

    // To change the loading icon behavior
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
 if (this.state.redirectToReferrer) {
return (<Redirect to={`/`}/>)
}
 if (this.state.redirect) {
return (<Redirect to={`/pos/${localStorage.getItem('userData')}/${localStorage.getItem('posId')}`}/>)
}
 if (this.state.redirect_to_return) {
return (<Redirect to={`/return-invoice/${localStorage.getItem('userData')}/${this.state.return_id}`}/>)
}
    return (
      <div>
      <Header />
      <div className="dashboard_body" id="pos_dashb">
      <div className="pos_main" id="select_pro">
      <div className="pos_black_box" id="pos_listgs">
       <div className=" main_body_style bg_detail"  style={{height:'auto'}}>

          <div className=" order_lists order_list_scrol" id="order_lists_load">
             <div className="user_login ">
              <div className="form_box invoice_list_page user_login_n cart-page">
                  <h3>Invoice List</h3>
                </div>
                {(photos!='')?

                  photos.map((item,key)=>(

                <div className="row product_layout_n" id="product_row"  key={key}>
                  <div className="col-sm-2 product_img">
                    <img src={this.state.img_url+`${item.profile_img}`} />
                  </div>
                  <a>

                  <div className="col-sm-8 product_contents">

                  <p>{item.created_on}</p> 
                    <div className="quantity_box">
                    <b>{this.state.prefix } {parseFloat(item.total).toFixed(this.state.decimalValue)}{this.state.suffix}</b>
                  </div>
                  </div>
                  <div className="col-sm-2 product_price">
                  <br />
                  <p>Invoice#: {item.salesInvoiceNo}</p>
                   <p style={{marginTop:'6px'}}>{item.ledgerName }</p>
                   <div className="return_action">
                   <i className="fa fa-edit" onClick={()=>this.editData(item.salesInvoiceNo)} ></i>
                   {
                    this.state.can_return==1&&
                      <i className="fa fa-refresh" onClick={ ()=> this.returnInvoiceHandler(item.salesInvoiceNo)}></i>
                   }
                   {
                    this.state.can_cancel==1&&
                      <i className="fa fa-close"></i>
                   }
                      
                   
                   
                   </div>
                  </div>
                  </a>
                </div>
                  )):
                  <div className="row product_layout">
                  <div className="col-sm-8 product_contents">
                    <h4>No order yet</h4>
                    </div>
                </div>

                }


            </div>
          </div>
        </div>
        <div className="fixed_loader"
          ref={loadingRef => (this.loadingRef = loadingRef)}
          style={loadingCSS}
        >
          <span style={loadingTextCSS} className="loader_on_listing"><img src={loading}  alt="Loading..."/></span>
        </div>
      </div>
      </div>
      </div>
         
      </div>
    );
  }
}

export default PosListing;
