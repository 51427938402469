import React, { Component } from "react";
import $ from "jquery";
import '../assets/css/bootstrap.css';
import '../assets/css/bootstrap.min.css';
import '../assets/css/pos.css';
import '../assets/css/font-awesome.min.css'; 
import logo from "../assets/images/logo-eloerp.png"; 
import swal from 'sweetalert'; 
import { NavLink } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { api_url } from "../Config.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
class SaleOrder extends Component {
   constructor(props) {
    super(props);
    this.state = {
      data: [],
      saleman: [],
      customers: [],
      ordertype: [],
      products: [],
      totalorder: null,
      redirectToReferrer: false,
      hours: "",
      items: [],
      users: [],
      warehouse_id: localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : 1,
      item_name: this.props.match.params.item_id,
      user_id:localStorage.getItem("userData"),
      base_url: api_url.base_url,
      validErr: "",
      startDate:null,
      redirect: false,
      customer_id: "",
      order_no: 1,
      date: null,
      order_type: "",
      auto_dis: 0,
      auto_disErr: "",
      dis_perc: 1,
      loading: false,
      posId:
        this.props.match.params.posId !== "undefined"
          ? this.props.match.params.posId
          : "",
      editData: [],
      cus_name:'',
      cus_phone:'',
      cus_address:'',
      cus_city:'',
      moveForward:false
    };
    // window.location.reload(true)
    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.validation = this.validation.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChanging = this.onChanging.bind(this);
    this.changeCustomerId = this.changeCustomerId.bind(this);
  }
   componentDidMount() {
    //
     
    $("#add_new_customer").hide();
    this.setState({ loading: true });

    const getUrl = this.state.base_url + "sale_api/getAllDetailsOnce?warehouse_id="+this.state.warehouse_id+"&user_id="+this.state.user_id;;
    fetch(getUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.totalPosOrder) {
                this.setState({
                  totalorder: parseFloat(result.totalPosOrder) + parseFloat(1)
                })
            }else{
                this.setState({
                  totalorder: parseFloat(1),
                })
            }
          this.setState(
            {
              saleman: result.customers,
              ordertype: result.ordertype,
              order_type: result.ordertype.id ? result.ordertype.id :0,
              products: result.products,
              loading: false,
            }
          );
        },
        (error) => {}
      );

    if (localStorage.getItem("posId") !== null) {
      const getIdUrl =
        this.state.base_url +
        "sale_api/getInvoice/" +
        this.state.user_id+"/"+
        localStorage.getItem("posId");
      fetch(getIdUrl)
        .then((res) => res.json())
        .then(
          (result) => {

            this.setState(
              {
                editData: result.model.data,
                loading: false,
              },
              function () {
                // localStorage.setItem('total_amount',(Math.round(result.basic_details.total)).toFixed(0))
                // console.log(localStorage.getItem('total_amount'))
              }
            );
          },
          (error) => {}
        );
    }

    let userID = this.state.user_id;
    const apiUrl =
      this.state.base_url + `sale_api/getUserName?userId=${userID}`;
    fetch(apiUrl)
      .then((res) => res.json())
      .then(
        (result) => {

        },
        (error) => {
          this.setState({ error });
        }
      );

      const customerUrl =
      this.state.base_url + `sale_api/getCustomers/${this.state.user_id}`;
    fetch(customerUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            customers: result.customers,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );

    $(".request_door_click").on("click", function () {});
    $(".request_door_click").click(function () {
      $(".process_complete").hide();
    });
    $(".request_door_click").click(function () {
      $(".last_step").show();
    });
  }

  componentWillMount() {
    if (sessionStorage.getItem("userData")) {
      this.getUserData();
    } else if (localStorage.getItem("userData")) {
      this.getUserData();
    } else {
      this.setState({ redirectToReferrer: true });
    }
  }
  getUserData() {
    JSON.parse(sessionStorage.getItem("userData"));
    JSON.parse(localStorage.getItem("userData"));
  }
  onChange(e) {
    localStorage.setItem("hours", e.target.value);
    this.setState(
      { to: `/address-details/${this.props.match.params.item_id}` },
      function () {}
    );
  }
onSave(e){
    this.setState({[e.target.name]:e.target.value});
}
  onChanging(date) {
    this.setState({ date });
  }
  handleChange = (date) =>{
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    var month = m+parseFloat(1);
    var formatedDate = y+'-'+month+'-'+d;
    this.setState({date:formatedDate})
  }

  validation() {
    if (this.state.auto_dis == "") {
      this.setState({
        auto_disErr: "* Please Enter Discount",
        redirect: false,
      });
    } else {
      return true;
      this.setState({ loading: true });
    }
  }
  refresh() {

    if (this.state.customer_id=='add') {

        if (this.state.cus_name=='') {
            swal('Customer Name is required');
            return false;
        }

      this.setState({loading:true,moveForward:false})

      const save_customer = this.state.base_url+'sale_api/addCustomer';
            fetch(save_customer,
            {
                method: 'POST',
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify({name:this.state.cus_name,phone:this.state.cus_phone,user_id:this.state.user_id,city:this.state.cus_city,address:this.state.cus_address})
            })
            .then((response) => response.json()
                .then((result) => {
                   if (result.id) {
                        this.setState({
                         customer_id:result.id,
                       },()=>{
                           var disval = $("#auto_dis").val();
                            var customerid = $("#customer_id").val();
                            var ordertype = $("#order_type").val();
                            var discountPercentage = $("#dis_perc").val();
                            this.setState(
                              { auto_dis: disval, customer_id: customerid, order_type: ordertype, dis_perc:discountPercentage },
                              function () {
                                if (this.validation() == true) {
                                  this.setState({ loading: true });
                                  // localStorage.setItem('prevState',this.state);
                                  // this.setState({ redirect: true });
                                  // PostData("basic_details", this.state).then((result) => {
                                    const order_not = this.state.editData.salesInvoiceNo
                                                  ? this.state.editData.salesInvoiceNo
                                                  : this.state.totalorder
                                    localStorage.setItem("order_no", order_not);
                                    localStorage.setItem("totalorder", this.state.totalorder);
                                    localStorage.setItem("discount", this.state.auto_dis);
                                    localStorage.setItem("dis_type", this.state.dis_perc);
                                    localStorage.setItem("customer_id", result.id);
                                    localStorage.setItem("order_type", this.state.order_type);
                                    localStorage.setItem("user_id", this.state.user_id);
                                    localStorage.setItem("editData", JSON.stringify(this.state.editData));
                                    this.setState({ redirect: true });
                                  // });
                                }
                              }
                            );
                       })
                   }
            }))
            .catch((error) => {
                this.setState({loading:false})
            });
    }else{
        var disval = $("#auto_dis").val();
        var customerid = $("#customer_id").val();
        var ordertype = $("#order_type").val();
        var discountPercentage = $("#dis_perc").val();
        this.setState(
          { auto_dis: disval, customer_id: customerid, order_type: ordertype, dis_perc:discountPercentage },
          function () {
            if (this.validation() == true) {
              this.setState({ loading: true });
              // localStorage.setItem('prevState',this.state);
              // this.setState({ redirect: true });
              // PostData("basic_details", this.state).then((result) => {
                const order_not = this.state.editData.salesInvoiceNo
                              ? this.state.editData.salesInvoiceNo
                              : this.state.totalorder
                localStorage.setItem("order_no", order_not);
                localStorage.setItem("totalorder", this.state.totalorder);
                localStorage.setItem("discount", this.state.auto_dis);
                localStorage.setItem("dis_type", this.state.dis_perc);
                localStorage.setItem("customer_id", this.state.customer_id);
                localStorage.setItem("order_type", this.state.order_type);
                localStorage.setItem("user_id", this.state.user_id);
                localStorage.setItem("editData", JSON.stringify(this.state.editData));
                this.setState({ redirect: true });
              // });
            }
          }
        );
    }


  }

  changeCustomerId(event){
    if (event.target.value=='add') {
        $("#add_new_customer").show();
            this.setState({
            customer_id: event.target.value,
          })
    }else{
      this.setState({
        customer_id: event.target.value,
      });
      $("#add_new_customer").hide();
    }

  }

  render() {
    // window.location.reload(true)
    const date = new Date();
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    var month = m+parseFloat(1);
    var formatedDate = y+'-'+month+'-'+d;
    const setDate = (this.state.date !==null) ? this.state.date : formatedDate;
    const ndate = this.state.editData.deliverDate? this.state.editData.deliverDate: setDate;
    const { items, saleman } = this.state;
    const { users } = this.state.users;
    const { loading } = this.state;
    if (this.state.redirectToReferrer) {
      return <Redirect to={`/login/${this.props.match.params.posId}`} />;
    }
    if (this.state.redirect) {
      if (localStorage.getItem("posId")) {
        return (
          <Redirect
            to={`/pos/${localStorage.getItem("userData")}/${localStorage.getItem("posId")}`}
          />
        );
      } else {
        return <Redirect to={`/pos/${localStorage.getItem("userData")}`} />;
      }
    }


    return (
      <div>
        <div className="dashboard_body"  id="sale_order_page">
          <div className="pos_main">
              <div className="pos_black_box">
                <div className="row" id="dashb_p">
                  <div className="col-sm-2 logo_box">
                  <NavLink to={`/dashboard/${localStorage.getItem('userData')}`}><img src={logo} alt=""/></NavLink>
                  </div>
                  <div className="col-sm-10 search_box">
                    <div className="head_title">
                      <h4>Invoice</h4>
                    </div>
                  </div>
                </div>

              <div className="main_body" id="sale_order_scroll">
                <div className="login_screen" id="uder_eng_language">
                  <div className="user_login Select_language process_complete">
                    <div className="form_box_delivery">
                      <div className="row">
                        <div
                          className="col-sm-6 delivery_address"
                          style={{ display: "block" }}
                        >
                          <label>Inovice No</label>
                          <input
                            type="text"
                            id="route"
                            name="street"
                            defaultValue={
                              this.state.editData.salesInvoiceNo
                                ? this.state.editData.salesInvoiceNo
                                : this.state.totalorder
                            }
                            readOnly={true}
                          />
                        </div>
                        <div className="col-sm-6 delivery_address">
                          <label style={{ zIndex: 1 }}>Date</label>
                          <DatePicker
                           value = {ndate}
                            onChange={(e)=>this.handleChange(e)}
                          />
                        </div>
                      </div>

                      

                      <div className="row">
                  <div
                    className="col-sm-12 delivery_address"
                    style={{ display: "block" }}
                  >
                    <label>Customer/Cash</label>
                    <select
                      id="customer_id"
                      name="customer_id"
                      onChange={this.changeCustomerId
                      }
                    >

                      {this.state.customers.length > 0 && this.state.customers.map((o, i) => (
                        <option
                          key={i}
                          value={o.id}
                          selected={
                                      this.state.editData.customerId ===
                                      o.id
                                    }
                        >
                          {o.ledgerName}
                        </option>

                      ))
                    }
                    {this.state.customers.length > 0 &&
                    <option value="add">Add Customer</option>
                  }


                    </select>
                  </div>
                </div>
                <div className="row" id="add_new_customer">
                <div className="col-sm-6 delivery_address">
                <label>Name</label>
                <input type="text" id="name" name="cus_name"  onChange={this.onSave}/>
                </div>


                <div className="col-sm-6 delivery_address">
                <label>Phone Number</label>
                <input type="text" id="phone" name="cus_phone" onChange={this.onSave}/>
                </div>

                <div className="col-sm-6 delivery_address">
                <label>City</label>
                <input type="text" id="cus_city" name="cus_city" onChange={this.onSave}/>
                </div>

                <div className="col-sm-6 delivery_address">
                <label>Address</label>
                <input type="text" id="cus_address" name="cus_address" onChange={this.onSave}/>
                </div>
                </div>

                      <div className="row ">
                        <div className="col-sm-12 delivery_address">
                          <label>Invoice Type</label>
                          <select
                            id="order_type"
                            name="area"
                            onChange={(event) =>
                              this.setState({
                                order_type: event.target.value,
                              })
                            }
                          >
                            <option defaultValue='Cart'>
                                Cart
                              </option>

                          </select>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6 delivery_address delivery_address_8">
                          <label>Discount</label>
                          <input
                            type="text"
                            className="form-control"
                            id="auto_dis"
                            name="auto_dis"
                            value={this.state.auto_dis}
                            onChange={(event) =>
                              this.setState({
                                auto_dis: event.target.value,
                              })
                            }
                            required={true}
                          />
                          <p style={{ color: "red", fontSize: "14px" }}>
                            {" "}
                            {this.state.auto_disErr}
                          </p>
                        </div>
                        <div className="col-sm-6 delivery_address delivery_address_4">
                          <select
                            id="dis_perc"
                            onChange={(event) =>
                              this.setState({
                                dis_perc: event.target.value,
                              })
                            }
                          >
                            <option
                              value="1"
                              defaultValue={
                                this.state.editData
                                  .discount_selected_option === "1"
                              }
                            >
                              %
                            </option>
                            <option
                              value="2"
                              defaultValue={
                                this.state.editData
                                  .discount_selected_option === "2"
                              }
                            >
                              Fix
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="form_box Request_door" id="request_door">
                      <button
                        className="button-loadings"
                        style={{ fontSize: "18px", width: "100%" }}
                        type="button"
                        onClick={this.refresh.bind(this)}
                        disabled={loading}
                      >
                        {loading && (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" }}
                          />
                        )}
                        {loading && <span>Loading...</span>}
                        {!loading && <span>Continue <i className="Continue_arrow fa fa-long-arrow-right"></i></span>}
                      </button>
                    </div>
                  </div>
                </div>
              </div>



              </div>
            </div>
        </div>
      </div>
    );
  }
}
export default SaleOrder;
